import axios from "axios";
let token = localStorage.getItem('token');
export default class MakeRequestService {

    async getRequest(url) {
        const response = await axios.get(url, {
            headers: {
                token: token
            }
        });
        return response.data;
    }

    async getRequestQuery(url, params) {
        const response = await axios.get(url, {
            params: params,
            headers: {
                token: token
            }
        })
        return response.data;
    }

    async postRequest(url, data) {
        const response = await axios.post(url, data, {
            headers: {
                token: token
            }
        });
        return response.data
    }

    async postRequestFormData(url, data) {
        const response = await axios.post(url, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                token: token
            }
        });
        return response.data
    }

    async putRequest(url, data) {
        const response = await axios.put(url, data, {
            headers: {
                token: token
            }
        });
        return response.data
    }
    
    async deleteRequest(url) {
        const response = await axios.delete(url, {
            headers: {
                'Access-Control-Allow-Methods': 'DELETE',
                'Content-Type': 'application/json',
                'token': token
            }
        })
        return response
    }
}