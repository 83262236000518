import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('./pages/Dashboard.vue'),
                meta: {
                    requiresAuth: false,
                    isTradeUser: true,

                }

            },
            // {
            //     path: '/componentDash',
            //     name: 'componentDash',
            //     component: () => import('./components/DashBoard.vue'),
            //     meta: {
            //         requiresAuth: true,
            //     }

            // },
            {
                path: '/pendientesGlobales',
                name: 'pendientesGlobales',
                component: () => import('./pages/PendientesG.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true
                }
            },
            {
                path: '/pendientesLista',
                name: 'pendientesLista',
                component: () => import('./pages/PendientesL.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/pendientesSku',
                name: 'pendientesSku',
                component: () => import('./pages/PendientesSku.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/totalesMarca',
                name: 'totalesMarca',
                component: () => import('./pages/TotalesMarca.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/simulacion',
                name: 'simulacion',
                component: () => import('./pages/Simulacion.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/sobrantesReport',
                name: 'sobrantesReport',
                component: () => import('./pages/SobrantesReport.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/despachoConsultar',
                name: 'despachoConsultar',
                component: () => import('./pages/Despachos.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/LEinSystem',
                name: 'LEinSystem',
                component: () => import('./pages/LeInSystem.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                }
            },
            {
                path: '/marca',
                name: 'marca',
                component: () => import('./pages/Marca.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/pedidos',
                name: 'pedidos',
                component: () => import('./pages/Pedidos.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/notificaciones',
                name: 'notificaciones',
                component: () => import('./pages/NotificacionesTabla.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/usuarios',
                name: 'usuarios',
                component: () => import('./pages/Usuarios.vue'),
                meta: {
                    requiresAuth: false,
                    tradeAdmin: true,
                }
            },
            {
                path: '/pronosticos',
                name: 'pronosticos',
                component: () => import('./pages/Pronostico.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/cierres',
                name: 'cierres',
                component: () => import('./pages/Cierres.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/crearPqc',
                name: 'crearPqc',
                component: () => import('./pages/CrearPqc.vue'),
                meta: {
                    requiresAuth: true,
                    noTradeUser: false,
                },
            },
            {
                path: '/allPqc',
                name: 'allPqc',
                component: () => import('./pages/AllPqc.vue'),
                meta: {
                    requiresAuth: true,
                    noTradeUser: false,
                },
            },
            {
                path: '/allPrePqc',
                name: 'allPrePqc',
                component: () => import('./pages/AllPrePqc.vue'),
                meta: {
                    requiresAuth: true,
                    noTradeUser: false,
                },
            },
            {
                path: '/marcasPqc',
                name: 'marcasPqc',
                component: () => import('./pages/MarcasPqc.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
            {
                path: '/lepqc',
                name: 'lepqc',
                component: () => import('./pages/PqcLes.vue'),
                meta: {
                    requiresAuth: false,
                    noTradeUser: true,
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/notfoundRole',
        name: 'notfoundRole',
        component: () => import('./pages/NotFoundRole.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/registrar',
        name: 'registrar',
        component: () => import('./pages/Registrar.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'errore',
        component: () => import('./pages/NotFound.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //Authentication Check
        const token = localStorage.getItem('token')

        if (token) {
            //Check if token is valid
            return next()
        }
        return next('/login')
    }

    if (to.matched.some(record => record.meta.requireRole)) {
        //Authentication Check
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('roleUser')

        if (token) {
            //Check if token is valid
            if (role == 'DEVELOPER' || role == 'ANALYTIC_ADMIN') {

                return next()
            }
            return next('/notfoundRole')
        }
        return next('/login')

    }


    if (to.matched.some(record => record.meta.noTradeUser)) {
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('roleUser')

        if (token) {
            //Check if token is valid
            if (role == 'TRADE_USER' || role == 'TRADE_ADMIN') {
                return next('/notfoundRole');
            }
            return next();
        }
        return next('/login')

    }

    if (to.matched.some(record => record.meta.tradeAdmin)) {
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('roleUser')

        if (token) {
            //Check if token is valid
            if (role == 'TRADE_USER') {
                return next('/notfoundRole');
            }
            return next();
        }
        return next('/login')

    }

    if (to.matched.some(record => record.meta.isTradeUser)) {
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('roleUser')

        if (token) {
            //Check if token is valid
            if (role == 'TRADE_USER' || role == 'TRADE_ADMIN') {
                return next('/crearPqc');
            }
            return next();
        }
        return next('/login')

    }

    next()

})

export default router;
