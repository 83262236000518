<template>
    <Toast />
    <div v-if="online == false" class="bottombar mb-1">
        <h3 style="color: white; text-align: center;" class="mt-2 mb-2">Estás desconectado</h3>
    </div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
    components: { Toast },
    data() {
        return {
            online: null
        }
    },
    mounted() {
        setInterval(this.checkNetwork, 1);
    },

    methods: {
        showToast(value) {
            this.$toast.add({ severity: 'info', summary: 'Info Message', detail: `La petición con ID #${value} fue enviada`, life: 3000 });
        },
        checkNetwork() {

            if (navigator.onLine) {

                this.online = navigator.onLine
             
            } else {
                this.online = navigator.onLine

            }

        }


    }

}
</script>

<style>
.bottombar {
    background-color: #F95757;
    width: 100%;
    border-radius: 10px;
}
</style>