<template>
	<div class="layout-footer">
		
		
		<span class="font-medium ml-2">made rigth by</span><img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/2becommerce.webp' : 'images/2becommerce.webp';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>