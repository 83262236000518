<template>
  <Dialog :visible="visible" header="Notificaciones" :showHeader="false" @hide="closePanel" class="notificacion-dialog"
    style="position: absolute; top: 0; right: 0; margin-top: 5rem; width: 30rem; height: 35rem; border-radius: 15px;">
    <div style="margin-top: 1rem; position: relative;">
      <h3>Notificaciones</h3>
      <button @click="closePanel" class="close-button">✕</button>
      <div class="overflow-auto">
        <Card v-for="notification in unreadNotifications" :key="notification._id" class="notification-card"
          @click="handleCardClick(notification)">
          <template #title>
            <div class="notification-header">
              <span class="notification-title">{{ notification.title }}</span>
              <span class="notification-date">{{ moment(notification.createdAt).format('DD-MM-YYYY HH:mm A') }}</span>
            </div>
          </template>
          <template #content>
            <span v-html="replaceEmoji(notification.texto)"></span>
          </template>
        </Card>
      </div>
    </div>
    <h6 style="cursor: pointer; text-align: right; margin: 0; padding: 0; color:#007bff; font-weight: bold"
      @click="goNotifi()">Ver todas</h6>
  </Dialog>
</template>

<script>
import moment from 'moment';
import Dialog from 'primevue/dialog';
import Card from 'primevue/card';
import MakeRequestService from '../service/MakeRequestService';
import logo from '../../public/images/pedi2icon.png';

export default {
  components: {
    Dialog,
    Card,
    logo
  },
  data() {
    return {
      notifications: [],
      unreadNotifications: [],
      readNotifications: []
    };
  },
  created() {
    this.makeRequestService = new MakeRequestService();
    this.fetchNotifications();
    setInterval(this.fetchNotifications, 120000);
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    moment() {
      return moment;
    }
  },
  watch: {
    visible(val) {
      if (val) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    }
  },
  methods: {
    closePanel() {
      this.$emit('close');
    },
    handleClickOutside(event) {
      const notificationBell = document.querySelector('.notification-bell');
      if (this.visible && !this.$el.contains(event.target) && !notificationBell.contains(event.target)) {
        this.closePanel();
      }
    },
    emitNotificationChange() {
      this.$emit('notificationChange', this.unreadNotifications.length);
    },
    async fetchNotifications() {
      this.makeRequestService.getRequest('dash/notifications').then(data => {
        if (data.error == null) {
          this.notifications = data.data.data;
          this.unreadNotifications = this.notifications.filter(notification => !notification.read_by || notification.read_by.length === 0).slice(0, 10);
          this.readNotifications = this.notifications.filter(notification => notification.read_by && notification.read_by.length > 0);
          this.emitNotificationChange();
        } else {
          console.error('Error en la petición:', data.error);
        }
      });
    },
    async goNotifi() {
      this.$router.push('/notificaciones');
      this.$emit('close');
    },
    async handleCardClick(notification) {
      try {
        const idUser = localStorage.getItem('idUser');
        const nameUser = localStorage.getItem('nameUser');
        const response = await this.makeRequestService.postRequest('dash/notifications', { id: notification._id, user_id: idUser, user_email: nameUser });

        notification.title === 'Despacho' ? (this.$router.push('/despachoConsultar'), this.$emit('close')) :
          notification.title === 'Lista de Empaque' ? (this.$router.push('/lepqc'), this.$emit('close')) :
            notification.title === 'PQC' ? (this.$router.push('/allPqc'), this.$emit('close')) :
              notification.title === 'Pedido' ? (this.$router.push('/pedidos'), this.$emit('close')) :
                '';
        await this.fetchNotifications();

      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo marcar como vista', life: 4000 });
      }
    },
    replaceEmoji(text) {
      return text
        .replace(/:white_check_mark:/g, '✅')
        .replace(/:pedi2:/g, `<img src="${logo}" class="app-logo" alt="Logo de tu app" style="width: 1em; height: 1em;">`);
    }
  }
};
</script>

<style scoped>
.notificacion-dialog {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5rem;
  width: 30rem;
  height: 35rem;
  border-radius: 15px;
}

.notification-card {
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0rem;
  background-color: rgb(243, 248, 249);
  cursor: pointer;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-title {
  font-weight: bold;
}

.notification-date {
  font-size: 0.85rem;
  color: #8d8d8d;
}

.close-button {
  height: 1px;
  position: absolute;
  top: 0.0rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
