<template>
    <Toast />
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            <Button style="color: #049AB9; text-align: center;" type="button" icon="pi" label="Ir versión anterior"
                class="p-button-outlined ml-6 mt-5" v-tooltip.top="'Ir a la versión anterior'"
                @click="openVersionAnterior" />
        </div>
        <div class="layout-main-container wrapper">
            <Offline />
            <div class="layout-main">
                <router-view />

            </div>
            <AppFooter />
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import Offline from './components/Offline.vue';

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            online: true,
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [
                {
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/', visible: () => this.hideMenu() }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Pedidos', icon: 'pi pi-fw pi-shopping-cart', to: '/pedidos',
                            visible: () => this.hideMenu()
                        },
                        {
                            label: 'Marcas', icon: 'pi pi-fw pi-th-large', to: '/marca',
                            visible: () => this.hideMenu()
                        },
                        {
                            label: 'Marcas PQC', icon: 'pi pi-fw pi-qrcode', to: '/marcasPqc', visible: () => this.hideMenu(),
                        },
                        {
                            label: 'LE en sistema', icon: 'pi pi-fw pi-server', to: '/LEinSystem',
                            visible: () => this.hideMenu()
                        },
                        {
                            label: 'Despachos', icon: 'pi pi-fw pi-car', to: '/despachoConsultar',
                            visible: () => this.hideMenu()

                        },
                        {
                            label: 'Pendientes', icon: 'pi pi-fw pi-calendar',
                            visible: () => this.hideMenu(),
                            items: [
                                {
                                    label: 'Pendientes Globales', icon: 'pi pi-fw pi-globe', to: '/pendientesGlobales',


                                },
                                {
                                    label: 'Pendientes Lista', icon: 'pi pi-fw pi-book', to: '/pendientesLista',


                                },
                                {
                                    label: 'Totales por marca', icon: 'pi pi-fw pi-th-large', to: '/totalesMarca',


                                },
                                {
                                    label: 'Pendientes Sku', icon: 'pi pi-fw pi-sort-alpha-down', to: '/pendientesSku',


                                },
                            ]
                        },
                        {
                            label: 'PQC/ESP', icon: 'pi pi-fw pi-chart-bar',
                            items: [
                                {
                                    label: 'Crear', icon: 'pi pi-fw pi-box', to: '/crearPqc',

                                },
                                {
                                    label: 'Ver', icon: 'pi pi-fw pi-eye',
                                    items: [
                                        { label: `PRE-PQC/ESP's`, icon: 'pi pi-fw pi-inbox', to: '/allPrePqc' },
                                        { label: `PQC/ESP's`, icon: 'pi pi-fw pi-check-square', to: '/allPqc' },
                                        { label: `Listas de empaque`, icon: 'pi pi-fw pi-check-square', to: '/lepqc', visible: () => this.hideMenu() }
                                    ]
                                }

                            ]
                        },
                        {
                            label: 'Sobrantes', icon: 'pi pi-fw pi-search-minus', to: '/sobrantesReport',
                            visible: () => this.hideMenu()
                            // items: [
                            //     {
                            //         label: 'Sobrantes Todos', icon: 'pi pi-fw pi-shopping-bag', to: '/sobrantesAll',

                            //     },
                            //     {
                            //         label: 'Sobrantes Reporte', icon: 'pi pi-fw pi-shopping-bag', to: '/sobrantesReport',

                            //     },
                            // ]
                        },
                        {
                            label: 'Pronostico', icon: 'pi pi-fw pi-cloud', to: '/pronosticos',
                            visible: () => this.hideMenu()
                        },
                        {
                            label: 'Cierres', icon: 'pi pi-fw pi-times', to: '/cierres',
                            visible: () => this.hideMenu()
                        },
                        {
                            label: 'Usuarios', icon: 'pi pi-fw pi-user-plus', to: '/usuarios',
                            visible: () => this.noTradeUser()
                        },
                        {
                            label: 'Simulación', icon: 'pi pi-fw pi-sync', to: '/simulacion',
                            visible: () => this.hideMenu()
                        },
                        {
                            label: 'Notificaciones', icon: 'pi pi-bell', to: '/notificaciones',
                            visible: () => this.hideMenu()
                        },
                    ]
                },
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        openVersionAnterior() {
            window.open("http://3.82.130.241:8081/", "_blank");
        },
        noTradeUser() {
            try {
                const role = localStorage.getItem('roleUser')
                //Check if token is valid
                if (role == 'TRADE_USER') {
                    return false
                }
                return true
            } catch (error) {
                console.log("noTradeUser ~ error:", error);
            }
        },
        isUserRole() {
            const role = localStorage.getItem('roleUser')
            //Check if token is valid
            if (role == 'DEVELOPER' || role == 'ANALYTIC_ADMIN') {
                return true
            } else if (role == 'TRADE_USER') {
                return false
            }
            return false
        },

        hideMenu() {
            const role = localStorage.getItem('roleUser')
            if (role == 'TRADE_USER' || role == 'TRADE_ADMIN') {
                return false;
            } else {
                return true;
            }
        },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
        'Offline': Offline
    }
}
</script>

<style lang="scss">
@import './App.scss';

body {
    background-image: linear-gradient(180deg, rgba(251, 251, 251, 0.8379726890756303) 0%, rgba(251, 251, 251, 0.84) 35%, rgba(251, 251, 251, 0.84) 100%), url("../public/images/image.png");
    background-repeat: repeat;

}

@media screen and (max-width : 425px) {


    .wrapper {
        display: flex !important;
        flex-direction: column !important;
        min-height: 100vh !important;
        justify-content: space-between !important;
        padding: 7rem 2rem 2rem 4rem !important;
        transition: margin-left 0.2s !important;
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }



}
</style>
