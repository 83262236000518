//RESOURCES

import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';


import { createApp, reactive } from 'vue';


//COMPONENTS PRIMEVUE

import axios from 'axios';
import moment from 'moment';
import router from './router';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import ProgressSpinner from 'primevue/progressspinner';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

//AXIOS CONFIG, HEADERS DEFAULT 

axios.defaults.headers.common["token"] = localStorage.getItem("token");
axios.defaults.baseURL = process.env.VUE_APP_RUTA_API;


const app = createApp(AppWrapper);

app.use(PrimeVue, {
	locale: {
		startsWith: 'Empieza con',
		contains: 'Contiene',
		notContains: 'No contiene',
		endsWith: 'Termina con',
		equals: 'Igual a',
		notEquals: 'No igual a',
		noFilter: 'No filtrar',
		lt: 'Menos que',
		lte: 'Menos que o igual a',
		gt: 'Mas que',
		gte: 'Mas que o igual a',
		dateIs: 'Fecha es',
		dateIsNot: 'Fecha no es',
		dateBefore: 'Fecha antes de',
		dateAfter: 'Fecha después de',
		clear: 'Limpiar',
		apply: 'Aplicar',
		matchAll: 'Coincidir con todos',
		matchAny: 'Coincidir con cualquiera',
		addRule: 'Añadir Reglas',
		removeRule: 'Remover Reglas',
		accept: 'Sí',
		reject: 'No',
		choose: 'Escoge',
		upload: 'Cargar',
		cancel: 'Cancelar',
		completed: 'Completado',
		pending: 'Pendiente',
		dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
		dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
		dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
		monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
		chooseYear: 'Escoge año',
		chooseMonth: 'Escoge mes',
		chooseDate: 'Escoge fecha',
		prevDecade: 'Previous Decade',
		nextDecade: 'Next Decade',
		prevYear: 'Previous Year',
		nextYear: 'Next Year',
		prevMonth: 'Previous Month',
		nextMonth: 'Next Month',
		prevHour: 'Previous Hour',
		nextHour: 'Next Hour',
		prevMinute: 'Previous Minute',
		nextMinute: 'Next Minute',
		prevSecond: 'Previous Second',
		nextSecond: 'Next Second',
		am: 'am',
		pm: 'pm',
		today: 'Today',
		weekHeader: 'Wk',
		firstDayOfWeek: 0,
		dateFormat: 'dd/mm/yy',
		weak: 'Weak',
		medium: 'Medium',
		strong: 'Strong',
		passwordPrompt: 'Ingresa la contraseña',
		emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
		searchMessage: '{0} results are available',
		selectionMessage: '{0} items selected',
		emptySelectionMessage: 'No selected item',
		emptySearchMessage: 'No se encontraron resultados',
		emptyMessage: 'No hay opciones disponibles',
		aria: {
			trueLabel: 'True',
			falseLabel: 'False',
			nullLabel: 'Not Selected',
			star: '1 star',
			stars: '{star} stars',
			selectAll: 'All items selected',
			unselectAll: 'All items unselected',
			close: 'Close',
			previous: 'Previous',
			next: 'Next',
			navigation: 'Navigation',
			scrollTop: 'Scroll Top',
			moveTop: 'Move Top',
			moveUp: 'Move Up',
			moveDown: 'Move Down',
			moveBottom: 'Move Bottom',
			moveToTarget: 'Move to Target',
			moveToSource: 'Move to Source',
			moveAllToTarget: 'Move All to Target',
			moveAllToSource: 'Move All to Source',
			pageLabel: '{page}',
			firstPageLabel: 'First Page',
			lastPageLabel: 'Last Page',
			nextPageLabel: 'Next Page',
			prevPageLabel: 'Previous Page',
			rowsPerPageLabel: 'Rows per page',
			previousPageLabel: 'Previous Page',
			jumpToPageDropdownLabel: 'Jump to Page Dropdown',
			jumpToPageInputLabel: 'Jump to Page Input',
			selectRow: 'Row Selected',
			unselectRow: 'Row Unselected',
			expandRow: 'Row Expanded',
			collapseRow: 'Row Collapsed',
			showFilterMenu: 'Show Filter Menu',
			hideFilterMenu: 'Hide Filter Menu',
			filterOperator: 'Filter Operator',
			filterConstraint: 'Filter Constraint',
			editRow: 'Row Edit',
			saveEdit: 'Save Edit',
			cancelEdit: 'Cancel Edit',
			listView: 'List View',
			gridView: 'Grid View',
			slide: 'Slide',
			slideNumber: '{slideNumber}',
			zoomImage: 'Zoom Image',
			zoomIn: 'Zoom In',
			zoomOut: 'Zoom Out',
			rotateRight: 'Rotate Right',
			rotateLeft: 'Rotate Left'
		}
	}
});

app.config.globalProperties.$appState = reactive({ theme: 'lara-light-indigo', darkTheme: false });
moment.locale('es');
app.config.globalProperties.$appDate = {
	formatDate(date) {
		return moment(date).utc().format("DD-MM-YYYY");
	},
}
app.config.globalProperties.$appDateMonth = {
	formatDateMonth(date) {
		return moment(date).utc().format("MMMM");
	},

}

app.config.globalProperties.$appDateMonthDay = {
	formatDateMonthDay(date) {
		return moment(date).utc().format("MMMM YY");
	},

}

app.config.globalProperties.$appDateMonthYear = {
	formatDateMonthYear(date) {
		return moment(date).utc().format("MMMM YYYY");
	},

}

app.config.globalProperties.$appDateMes = {
	formatDateMes(date) {
		let dateformat = moment(date).utc().format("MMMM");
		return dateformat.locale('es')
	},
}
app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(moment);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);

app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Galleria', Galleria);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);

app.mount('#app');



//===================================================================
// PUSH NOTIFICATIONS VARIABLES
//===================================================================

const publicKey = process.env.VUE_APP_PUBLIC_VAPID_KEY;
let token = localStorage.getItem("token");
let id = localStorage.getItem("idUser");
let regis;

//===================================================================
// REGISTER SERVICE WORKER
//===================================================================

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/sw_cached_front.js')
			.then(async (registration) => {

				regis = registration;

				console.log('Service Worker registration completed with scope: ', registration.scope);

				//===================================================================
				//  SEND PERMISSION FOR NOTIFICATIONS
				//===================================================================

				requestNotificationPermission(regis);

			}, (err) => {
				console.log('Service Worker registration failed', err);
			});
	});
} else {
	console.log('Service Workers not supported');
}


const requestNotificationPermission = async (registration) => {

	await window.Notification.requestPermission().then((permission) => {

		if (permission !== 'granted') {

			throw new Error('Permiso de notificaciones denegado');

		} else {

			// console.log('Make Subscription')

			if (token) {
				registration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(publicKey)

				}).then(async (subscription) => {

					// console.log('Subscription registered...', subscription);

					if (id) {

						let dataToSend = {
							idUser: id,
							subscription: subscription
						}

						//========================================
						// SEND SUBSCRIPTION TO BACKEND
						//========================================

						let response = await axios.post(process.env.VUE_APP_RUTA_API + 'pushNotification/subscription', dataToSend, {
							headers: {
								token: token
							}
						});

						localStorage.setItem('subscription', subscription)

						// console.log("Subscription saved", response);

					}

				}).catch(function (error) {
					console.log(error)
				});
			}


		}

	});

}

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length)

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}




