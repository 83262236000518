<template>
	<div class="layout-topbar" style="background-color: #049AB9;">
		<Toast />
		<router-link to="/" class="layout-topbar-logo">
			<div v-tooltip.top="'Volver al dashboard'" class="mt-2">
				<img alt="Logo" :src="topbarImage()" />
				<img alt="Letter" src="../public/images/pedi2letter.png" />
			</div>
			<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle"
				v-tooltip.top="'Esconder menú'" style="color: white;">
				<i class="pi pi-bars"></i>
			</button>
		</router-link>

		<button class="p-link layout-topbar-menu-button layout-topbar-button" v-styleclass="{
				selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
				leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true
			}">
			<i class="pi pi-ellipsis-v"></i>
		</button>

		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li style="padding-right: 1rem; height: 0rem;">
				<Button icon="pi pi-bell" type="button" :badge="badgeCount"
					style="background-color: #049AB9; border: #049AB9; width: 4rem;" @click="openNotificationPanel"
					class="notification-bell" />
			</li>

			<li>
				<h3 style="padding-top: 3.5%; color: white;">{{ nameUser }}</h3>
			</li>
			<li>
				<button class="p-link layout-topbar-button" style="color: white;" @click="logOut">
					<i class="pi pi-sign-out"></i>
					<span>Log Out</span>
				</button>
			</li>
		</ul>
		<NotificationPanel :visible="showNotificationPanel" @close="closeNotificationPanel"
			@notificationChange="updateNotifications" />
	</div>
</template>

<script>
import NotificationPanel from './components/NotificationPanel.vue';

export default {
	components: {
		NotificationPanel
	},
	data() {
		return {
			profileDialog: false,
			nameUser: localStorage.getItem('nameUser'),
			showNotificationPanel: false,
			unreadNotificationCount: 0
		}
	},
	methods: {
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		onTopbarMenuToggle(event) {
			this.$emit('topbar-menu-toggle', event);
		},
		topbarImage() {
			return this.$appState.darkTheme ? 'images/pedi2icon.png' : 'images/pedi2icon.png';
		},
		getDataUser() {
			this.nameUser = localStorage.getItem('nameUser');
		},
		logOut() {
			localStorage.clear();
			// window.location.reload();
			this.$router.push('/login');
		},
		openNotificationPanel() {
			this.showNotificationPanel = !this.showNotificationPanel;
		},
		closeNotificationPanel() {
			this.showNotificationPanel = false;
		},
		updateNotifications(unreadCount) {
			this.unreadNotificationCount = unreadCount;
		}
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		badgeCount() {
			return this.unreadNotificationCount >= 10 ? '10+' : this.unreadNotificationCount.toString();
		}
	}
}
</script>

<style lang="scss" scoped></style>